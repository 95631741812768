import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addAI3 } from "../../actions/formActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import  Web3 from 'web3';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))


let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false, 
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}
class ArticleImage3 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id:"",
            errors: {},
            file : {},
};
    }
   
    componentWillReceiveProps(nextProps) {
       
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }

        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined) {
            $('#add-ai3-modal').modal('hide');
            toast(nextProps.auth.form.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            
        }
    }
   
    onChange = e => {
        
        this.setState({ [e.target.id]: e.target.value });

    };
     
    onFileChange = event => {
        var name = event.target.value;
           // Update the state
           this.setState({ file: event.target.files[0]});
           this.setState({ imagepath: name});
         //console.log(name);
         };

    handleChange = (value) => {
        this.setState({ Description:value });       
       }

       onStakeAdd = e => {
        e.preventDefault();
        const newStake = {
            
            file: this.state.file
            
        };
       
        this.props.addAI3(newStake, this.props.history);
       // $('#add-pool-modal').modal('hide');
        
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-ai3-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Image</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate  id="add-stake">
                                
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="logoURI">Add Article3 Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onFileChange}
                                                value={this.state.imagepath}
                                                error={errors.logoURI}
                                                id="logoURI"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.logoURI
                                                })}
                                            />
                                            <span className="text-danger">{errors.logoURI}</span>
                                        </div>
                                    </div>
                                    
                                    
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <a
                                    form="add-stake"
                                  
                                    className="btn btn-primary" onClick={this.onStakeAdd}>
                                    Add
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ArticleImage3.propTypes = {
    addStake: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addAI3 }
)(withRouter(ArticleImage3));
