import {
    SET_CURRENT_USER,
    USER_ADD,
    FORM_ADD,
    POOL_ADD,
    IP_ADD,
    STAKE_ADD,
    LAUNCHPAD_ADD,
    FORM_UPDATE,
    POOL_UPDATE,
    IP_UPDATE,
    STAKE_UPDATE,
    LAUNCHPAD_UPDATE,
    USER_LOADING,
    FORM_LOADING,
    POOL_LOADING,
    IP_LOADING,
    STAKE_LOADING,
    LAUNCHPAD_LOADING,
    USER_UPDATE
} from "../actions/types";
const isEmpty = require("is-empty");
const initialState = {
    isAuthenticated: false,
    user: {},
    loading: false,
};
export default function(state = initialState, action) {
    switch (action.type) {
        case USER_ADD:
            return {
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
        case USER_UPDATE:
            return {
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload,
            };
        case FORM_ADD:
            return {
                isAuthenticated: !isEmpty(action.payload),
                form: action.payload
            };
        case FORM_UPDATE:
            return {
                isAuthenticated: !isEmpty(action.payload),
                form: action.payload,
            };
        case POOL_ADD:
                return {
                    isAuthenticated: !isEmpty(action.payload),
                    form: action.payload
                };
        case POOL_UPDATE:
                return {
                    isAuthenticated: !isEmpty(action.payload),
                    form: action.payload,
                };    
        case IP_ADD:
                return {
                    isAuthenticated: !isEmpty(action.payload),
                    form: action.payload
                };
        case IP_UPDATE:
                return {
                    isAuthenticated: !isEmpty(action.payload),
                    form: action.payload,
                };    
        case STAKE_ADD:
                    return {
                        isAuthenticated: !isEmpty(action.payload),
                        form: action.payload
                    };
        case STAKE_UPDATE:
                    return {
                        isAuthenticated: !isEmpty(action.payload),
                        form: action.payload,
                    }; 
        case LAUNCHPAD_ADD:
                        return {
                            isAuthenticated: !isEmpty(action.payload),
                            form: action.payload
                        };
        case LAUNCHPAD_UPDATE:
                        return {
                            isAuthenticated: !isEmpty(action.payload),
                            form: action.payload,
                        };                        
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
        case USER_LOADING:
            return {
                ...state,
                loading: true
            };
        case FORM_LOADING:
            return {
                ...state,
                loading: true
            };
        case POOL_LOADING:
            return {
                ...state,
                loading: true
            };
      case IP_LOADING:
            return {
                ...state,
                loading: true
            };
      case STAKE_LOADING:
            return {
                ...state,
                loading: true
            };
      case LAUNCHPAD_UPDATE:
                return {
                    ...state,
                    loading: true
                };      
            
        default:
            return state;
    }
}
