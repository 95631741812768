import axios from "axios";
import isEmpty from "is-empty";
import backurl from "../lib/config"
import {
    GET_ERRORS,
    STAKE_ADD,
    STAKE_UPDATE
} from "./types";

export const addStake = (param, history) => dispatch => {
   
    var stakeData = new FormData();
    
    stakeData.append('Heading',param.Heading );
    stakeData.append('Description',param.Description );
    if (!isEmpty(param.file)) {
        stakeData.append('file',param.file );
       
    }
    axios
        .post(backurl+"/api/add/news", stakeData)
        .then(res =>{   
           
            dispatch({
                type: STAKE_ADD,
                payload: res,
            })
        }
        ).catch(err =>{   
            // console.log("yyyhyu>>",err)
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
    }
    );
};

export const updateStake = (param) => dispatch => {
   
    var stakeUpdateData = new FormData();
    if (!isEmpty(param._id))   stakeUpdateData.append('_id',param._id );
    if (!isEmpty(param.Heading))stakeUpdateData.append('Heading',param.Heading);
    if (!isEmpty(param.Description))stakeUpdateData.append('Description',param.Description);

   
   
   
    axios
        .post(backurl+"/api/update/Roadmapdes", stakeUpdateData)
        .then(res =>
            dispatch({
                type: STAKE_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};



export const updateSettings = (param) => dispatch => {
   
    var stakeUpdateData = new FormData();
    if (!isEmpty(param._id))   stakeUpdateData.append('_id',param._id );
    if (!isEmpty(param.Heading))stakeUpdateData.append('Heading',param.Heading);
    if (!isEmpty(param.Description))stakeUpdateData.append('Description',param.Description);

   
   
   
    axios
        .post(backurl+"/api/update/setting", stakeUpdateData)
        .then(res =>
            dispatch({
                type: STAKE_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};